import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getLocalStorageInfo } from '../../../../utils';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
class CashFlowGraph extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  
  render() {
    var headingText = this.props.headingText
    var lessorDetail = this.props.lessorDetail
    var colors = [ '#3c7ac2', '#85c472', '#ee7c26','#E6B333', '#757526']
    const {jspLessor, cashflowSummaryWidget, selectedTab } = this.props;
    const options = {
      chart: {
        type: this.props.graphType,
        zoomType: 'x',
        height: '300',
        marginTop: 60,
        events: {
          load: function () {
            if (lessorDetail && lessorDetail.lessor_name) {
              var label = this.renderer.label(headingText + "<br/>Lessor: " + lessorDetail.lessor_name)
            } else {
              var label = this.renderer.label(headingText + "<br/>Lessor: " + getLocalStorageInfo().defaultLessor.name)
            }
            label.css({
              width: '400px',
              fontSize: '9px'
            })
              .attr({
                'stroke': 'silver',
                'stroke-width': 1,
                'r': 2,
                'padding': 5
              })
              .add();

            label.align(Highcharts.extend(label.getBBox(), {
              align: 'left',
              x: 0, // offset
              verticalAlign: 'bottom',
              y: -250 // offset
            }), null, 'spacingBox');
          },
        },
      },
      colors:colors,
      title: {
        text: this.props.graphTitle
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: this.props.graphxLabel
        },
        labels: {
          formatter: function () {
            return Highcharts.dateFormat('%b %Y', this.value?this.value:null); 
          },
        },
        tickInterval: 30 * 24 * 3600 * 1000, 
        min: this.getMinDate(cashflowSummaryWidget),
        max: this.getMaxDate(cashflowSummaryWidget),
      },
      tooltip: {
        crosshairs: true,
        shared: true,
      },
      lang: {
        noData: 'No Records Found'
      },
      noData: {
        style: {
          fontWeight: 'normal',
          fontSize: '20px',
          color: '#000',
          fontFamily: 'Conv_IBMPlexSans-Regular'
        }
      },

      yAxis: {
        title: {
          text: this.props.graphyLabel
        },
        labels: {
          formatter: function () {
            return this.value;
          }
        },
        gridLineDashStyle: 'longdash'
      },
      series: jspLessor ? selectedTab == 1 ?[
        {
          name: 'Rental Cash Flow',
          data: cashflowSummaryWidget?.currency_usd?.rental?.rental_plot && Object.keys(cashflowSummaryWidget.currency_usd.rental.rental_plot).map((year) => { return { x: new Date(year.slice(0, 4), parseInt(year.slice(4, 6), 10), 1).getTime(), y: cashflowSummaryWidget.currency_usd.rental.rental_plot[year] } })
        },
        {
          name: 'Esp Cash Flow',
          data: cashflowSummaryWidget?.currency_usd?.rental?.esp_plot && Object.keys(cashflowSummaryWidget.currency_usd.rental.esp_plot).map((year) => { return { x: new Date(year.slice(0, 4), parseInt(year.slice(4, 6), 10), 1).getTime(), y: cashflowSummaryWidget.currency_usd.rental.esp_plot[year] } })
        },
        {
          name: 'Lsp Cash Flow',
          data: cashflowSummaryWidget?.currency_usd?.rental?.lsp_plot && Object.keys(cashflowSummaryWidget.currency_usd.rental.lsp_plot).map((year) => { return { x: new Date(year.slice(0, 4), parseInt(year.slice(4, 6), 10), 1).getTime(), y: cashflowSummaryWidget.currency_usd.rental.lsp_plot[year] } })
        },
        {
          name: 'Jsp Cash Flow',
          data: cashflowSummaryWidget?.currency_usd?.rental?.jsp_plot && Object.keys(cashflowSummaryWidget.currency_usd.rental.jsp_plot).map((year) => { return { x: new Date(year.slice(0, 4), parseInt(year.slice(4, 6), 10), 1).getTime(), y: cashflowSummaryWidget.currency_usd.rental.jsp_plot[year] } })
        }]
        :
        [
          {
            name: 'MR Cash Flow',
            data: cashflowSummaryWidget?.currency_usd?.mr?.mr_plot && Object.keys(cashflowSummaryWidget.currency_usd.mr.mr_plot).map((year) => { return { x: new Date(year.slice(0, 4), parseInt(year.slice(4, 6), 10), 1).getTime(), y: cashflowSummaryWidget.currency_usd.mr.mr_plot[year] } }),
          },
          {
            name: 'Esp Cash Flow',
            data: cashflowSummaryWidget?.currency_usd?.mr?.esp_plot && Object.keys(cashflowSummaryWidget.currency_usd.mr.esp_plot).map((year) => { return { x: new Date(year.slice(0, 4), parseInt(year.slice(4, 6), 10), 1).getTime(), y: cashflowSummaryWidget.currency_usd.mr.esp_plot[year] } }),
          },
          {
            name: 'Lsp Cash Flow',
            data: cashflowSummaryWidget?.currency_usd?.mr?.lsp_plot && Object.keys(cashflowSummaryWidget.currency_usd.mr.lsp_plot).map((year) => { return { x: new Date(year.slice(0, 4), parseInt(year.slice(4, 6), 10), 1).getTime(), y: cashflowSummaryWidget.currency_usd.mr.lsp_plot[year] } })
          },
          {
            name: 'Jsp Cash Flow',
            data: cashflowSummaryWidget?.currency_usd?.mr?.jsp_plot && Object.keys(cashflowSummaryWidget.currency_usd.mr.jsp_plot).map((year) => { return { x: new Date(year.slice(0, 4), parseInt(year.slice(4, 6), 10), 1).getTime(), y: cashflowSummaryWidget.currency_usd.mr.jsp_plot[year] } }),
          }
        ] : [
        {
          name: 'MR Cash Flow',
          data: cashflowSummaryWidget?.currency_usd?.mr?.mr_plot && Object.keys(cashflowSummaryWidget.currency_usd.mr.mr_plot).map((year) => { return { x: new Date(year.slice(0, 4), parseInt(year.slice(4, 6), 10), 1).getTime(), y: cashflowSummaryWidget.currency_usd.mr.mr_plot[year] } })
        },
        {
          name: 'Rental Cash Flow',
          data: cashflowSummaryWidget?.currency_usd?.rental?.rental_plot && Object.keys(cashflowSummaryWidget.currency_usd.rental.rental_plot).map((year) => { return { x: new Date(year.slice(0, 4), parseInt(year.slice(4, 6), 10), 1).getTime(), y: cashflowSummaryWidget.currency_usd.rental.rental_plot[year] } })
        }
          ],
      credits: {
        enabled: false,
      },
      exporting: {
        buttons: {
          contextButton: {
            text: 'Export',
            menuItems: [
              {
                text: 'Export as PNG',
                onclick: function () {this.exportChart({ type: 'image/png' })}
              },
              {
                text: 'Export as SVG',
                onclick: function () {this.exportChart({ type: 'image/svg+xml' })}
              },
              {
                text: 'Export as PDF',
                onclick: function () {this.exportChart({ type: 'application/pdf' })},
              },
            ],
          },
        },
      },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }

  getMinDate(data) {
    let allDates = [];
    if (this.props.selectedTab === 1 && data?.currency_usd?.rental) {
      allDates = [
        ...Object.keys(data.currency_usd.rental.rental_plot),
        ...Object.keys(data.currency_usd.rental.esp_plot),
        ...Object.keys(data.currency_usd.rental.lsp_plot),
        ...Object.keys(data.currency_usd.rental.jsp_plot),
      ];
    }
    if (this.props.selectedTab === 0 && data?.currency_usd?.mr) {
      allDates = [
        ...allDates,
        ...Object.keys(data.currency_usd.mr.mr_plot),
        ...Object.keys(data.currency_usd.mr.esp_plot),
        ...Object.keys(data.currency_usd.mr.lsp_plot),
        ...Object.keys(data.currency_usd.mr.jsp_plot),
      ];
    }
    const minDate = Math.min(...allDates.map((year) => new Date(year.slice(0, 4), year.slice(4, 6), 1).getTime()));
      const date = new Date(minDate);
      date.setMonth(date.getMonth() - 1); 
  
    return date.getTime();
  }
  getMaxDate(data) {
    let allDates = [];
    if (this.props.selectedTab === 1 && data?.currency_usd?.rental) {
      allDates = [
        ...Object.keys(data.currency_usd.rental.rental_plot),
        ...Object.keys(data.currency_usd.rental.esp_plot),
        ...Object.keys(data.currency_usd.rental.lsp_plot),
        ...Object.keys(data.currency_usd.rental.jsp_plot),
      ];
    }
    if (this.props.selectedTab === 0 && data?.currency_usd?.mr) {
      allDates = [
        ...allDates,
        ...Object.keys(data.currency_usd.mr.mr_plot),
        ...Object.keys(data.currency_usd.mr.esp_plot),
        ...Object.keys(data.currency_usd.mr.lsp_plot),
        ...Object.keys(data.currency_usd.mr.jsp_plot),
      ];
    }
    const maxDate = Math.max( ...allDates.map((year) => new Date(year.slice(0, 4), year.slice(4, 6), 1).getTime()))
    const date = new Date(maxDate);
    date.setMonth(date.getMonth() + 1); 
    return date.getTime();
  }
}

export default CashFlowGraph;
