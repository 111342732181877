import { getLocalStorageInfo } from '../../utils_v2';
import { jetStreamInstance } from '../Leases';
export const globalFilterTypes = [
  {label:'lessee',type:'lesseeOptions'},
  {label:'lessor',type:'lessorOptions'},
  {label:'owner',type:'ownerOptions'},
  {label:'asset_type',type:'assetTypeOptions'},
  {label:'asset_model',type:'assetModelOptions'},
  {label:'serial_number',type:'assetsOptions'},
]
export const cashflowHd = [
  {label:'Month', sortOption:false},
  {label:'Name', sortOption:false},
  {label:'Credit', sortOption:false},
  {label:'Debit', sortOption:false},
  {label:'Balance', sortOption:false},
  {label:'Transaction Detail', sortOption:false}
]
export const fleetCashflowHd = [
  { id:'asset_type', label: 'Asset(s)', sortOption: true },
  { id:'lessor_name', label: 'lessor', sortOption: false },
  { id:'owner_name', label: 'owner', sortOption: false },
  { id:'lessee_id', label: 'Lessee', sortOption: false },
  { label: 'Lease Duration', sortOption: false },
  { label: 'Rate Type', sortOption: false },
  { id:'currency', label: 'Currency', sortOption: true },
  { id:'credit_or_debit',label: 'Credit', sortOption: false },
  { id:'credit_or_debit',label: 'Debit', sortOption: false },
  { id:'amount', label: 'Balance', sortOption: false },
  { label: 'Transaction Details', sortOption: false },
];
export const cashflowFilterOps = {
  currency: {
    inputType: 'dropdown',
    placeholder: 'Select Currency',
    options: [{label: 'USD', value: 'USD'}, {label: 'EURO', value: 'EUR'}],
    labelKey: 'label',
    valueKey: 'value',
    title:'Currency',
    multiple: false,
    showDropdown:true
  },

  reserve_type: {
    inputType: 'dropdown',
    placeholder: 'Select Reserve Rate Type',
    options: [{label: 'ESP', value: '2'}, {label: "JSP", value: '4'}, {label: 'LSP', value: '3'}, {label: 'MR', value: '1'}],
    labelKey: 'label',
    valueKey: 'value',
    title:'Reserve Rate Type',
    multiple: false,
    showDropdown:true
  },
  transaction_type: {
    inputType: 'dropdown',
    placeholder: 'Select Transaction Type',
    options: [{label: 'Credit Note Transaction', value: '5'}, {label: 'Debit Note Transaction', value: '4'},{label: 'MR Transaction', value: '1'},{label: 'Penalty Transaction', value: '3'} ,{label: 'Rental Transaction', value: '2'}],
    labelKey: 'label',
    valueKey: 'value',
    title:'Transaction Type',
    multiple: false,
    showDropdown:true
  },
  transaction_reference_id: {
    inputType: 'dropdown',
    placeholder: 'Select Reserve Account',
    options: [],
    labelKey: 'name',
    valueKey: 'id',
    title:'Reserve Account',
    multiple: false,
    showDropdown:true
  },
  date: {
    inputType: 'date',
    title: 'Date',
    firstKey:'startDate',
    lastKey:'endDate',
    range:true
  },
};

export const cashflowFleetFilterOps = {
  asset_type : {
    inputType: 'dropdown',
    placeholder: 'Select Asset Type',
    title: 'Asset Type',
    options: [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  asset_model: {
    inputType: 'dropdown',
    placeholder: 'Select Asset Model',
    title: 'Asset Model',
    options: [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  serial_number: {
    inputType: 'dropdown',
    placeholder: 'Select Serial No.',
    title: 'Serial No.',
    options: [],
    labelKey: 'serial_number',
    valueKey: 'id',
    multiple: true
  },
  lessee_id : {
    inputType: 'dropdown',
    placeholder: 'Select lessee',
    title: 'Lessee',
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  lessor_id : {
    inputType: 'dropdown',
    placeholder: `Select ${jetStreamInstance?'Lessor/Holdco':'Lessor'}`,
    title: `${jetStreamInstance?'Lessor/Holdco':'Lessor'}`,
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  owner_id : {
    inputType: 'dropdown',
    placeholder: `Select ${jetStreamInstance?'Owner SPV':'Owner'}`,
    title: `${jetStreamInstance?'Owner SPV':'Owner'}`,
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  transaction_start_date: {
    inputType: 'date',
    title: 'Transaction Start Duration',
  },
  transaction_end_date: {
    inputType: 'date',
    title: 'Transaction End Duration',
  },
  credit_min: {
    inputType: 'number',
    title: 'Credit Amount From',
    md:6
  },
  credit_max: {
    inputType: 'number',
    title: 'Credit Amount Till',
    md:6
  },
  debit_amount: {
    inputType: 'float',
    title: 'Debit Amount',
    firstKey:'debit_min',
    lastKey:'debit_max',
    md:6
  },
  debit_min: {
    inputType: 'number',
    title: 'Debit Amount From',
    md:6
  },
  debit_max: {
    inputType: 'number',
    title: 'Debit Amount Till',
    md:6
  },
  search: {
    inputType: 'text',
    placeholder: 'Search by transaction details',
    title:'Transaction Details'
  },
  currency: {
    inputType: 'dropdown',
    placeholder: 'Select Currency',
    options: [{label: 'USD', value: 'USD'}, {label: 'EUR', value: 'EUR'}],
    title:'Currency',
    labelKey: 'label',
    valueKey: 'value'
  },
  is_partial_paid : {
    inputType: 'dropdown',
    placeholder: 'Search by partial paid transaction',
    title: 'Due Invoices',
    options:  [{label: 'Yes', id: '0'}, {label: 'No', id: '1'}],
    labelKey: 'label',
    valueKey: 'id'
  },
  transaction_type : {
    inputType: 'dropdown',
    placeholder: 'Search by transaction invoice type',
    title: 'Transaction Invoice Type',
    options:  [{label: 'Reserve', id: '1'}, {label: 'Rental', id: '2' }, {label: 'Adhoc', id: '9'}, {label: 'Penalty', id: '3'}, {label: 'Debit', id: '4'},{label: 'Credit', id: '5'}],
    labelKey: 'label',
    valueKey: 'id',
    multiple: true
  },
  reserve_acc_type : {
    inputType: 'dropdown',
    placeholder: 'Select Reserve Rate Type',
    title: 'Reserve Rate Type',
    options:  getLocalStorageInfo() && getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.id === 442 ? [{label: 'ESP', id: '2'}, {label: 'JSP', id: '4'},  {label: 'LSP', id: '3'}, {label: 'MR', id: '1'}] : [{label: 'ESP', id: '2'}, {label: 'LSP', id: '3'}, {label: 'MR', id: '1'}],
    labelKey: 'label',
    valueKey: 'id',
    multiple: true
  },
  rental_acc_type : {
    inputType: 'dropdown',
    placeholder: 'Select Rental Rate Type',
    title: 'Rental Rate Type',
    options:  getLocalStorageInfo() && getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.id === 442 ? [{label: 'ESP', id: '1'}, {label: 'JSP', id: '3'},  {label: 'LSP', id: '2'}] : [{label: 'ESP', id: '1'}, {label: 'LSP', id: '2'}],
    labelKey: 'label',
    valueKey: 'id',
    multiple: true
  },
};

export const transTypeGraphFilterOps = {
  transaction_start_date: {
    inputType: 'date',
    title: 'Transaction Start Duration',
  },
  transaction_end_date: {
    inputType: 'date',
    title: 'Transaction End Duration',
  },
  reserve_acc_name: {
    inputType: 'text',
    placeholder: 'Search by Reserve Account Name',
    title:'Reserve Account Name'
  },
  reserve_acc_type : {
    inputType: 'dropdown',
    placeholder: 'Select Reserve Rate Type',
    title: 'Reserve Rate Type',
    options:  getLocalStorageInfo() && getLocalStorageInfo().defaultLessor?.id === 442 ? [{label: 'ESP', id: '2'}, {label: 'JSP', id: '4'},  {label: 'LSP', id: '3'}, {label: 'MR', id: '1'}] : [{label: 'ESP', id: '2'}, {label: 'LSP', id: '3'}, {label: 'MR', id: '1'}],
    labelKey: 'label',
    valueKey: 'id',
    multiple: true
  }
};
export const transTypeRentalGraphFilterOps = {
  transaction_start_date: {
    inputType: 'date',
    title: 'Transaction Start Duration',
  },
  transaction_end_date: {
    inputType: 'date',
    title: 'Transaction End Duration',
  },
  rental_acc_name: {
    inputType: 'text',
    placeholder: 'Search by Rental Account Name',
    title:'Rental Account Name'
  },
  rental_acc_type : {
    inputType: 'dropdown',
    placeholder: 'Select Rental Rate Type',
    title: 'Rental Rate Type',
    options:  getLocalStorageInfo() && getLocalStorageInfo().defaultLessor?.id === 442 ? [{label: 'ESP', id: '1'}, {label: 'JSP', id: '3'},  {label: 'LSP', id: '2'}] : [{label: 'ESP', id: '1'}, {label: 'LSP', id: '2'}],
    labelKey: 'label',
    valueKey: 'id',
    multiple: true
  }
};


export const ownerGraphFilterOps = {
  transaction_start_date: {
    inputType: 'date',
    title: 'Transaction Start Duration',
  },
  transaction_end_date: {
    inputType: 'date',
    title: 'Transaction End Duration',
  }
};
