import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Grid, Button, Paper, Dialog, DialogActions, DialogContent, DialogTitle, Tabs, Tab } from '@material-ui/core';
import { FleetGenericFilter } from '../../Elements'
import { TableListComp, PageLoader, ExportManu, EmptyCollection, FilterComponent } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { Switcher, FleetCashflowsList, CashflowLessorGraph, CashflowOwnerGraph, CashflowTransTypeGraph, CashflowAcTypeGraph } from '../components';
import { getPlatformConstantsLeaseTypeAc, getPlatformConstantsLeaseStatusAc } from '../../../shared_elements/actionCreators';
import { getAssetIdentifierApi, getFleetCashflowApi, exportFleetCashflowsApi, getCashflowAccTypeGraphApi, geCashflowTransTypeGraphApi, getCashflowOwnerGraphApi, getCashflowLessorGraphApi, getCashflowRenatlAccTypeGraphApi } from '../apiServices';
import { globalFilterTypes, fleetCashflowHd, cashflowFleetFilterOps } from '../';
import { monthsRevObj } from '../../../constants'
import { checkPermission } from '../../../utils_v2';
import { trackActivity } from '../../../utils/mixpanel';
import RoleBasedAccess from '../../../utils/RoleBasedAccess';
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
import { getLesseeFilterApi } from '../../Leases/apiServices';
import { jetStreamInstance } from '../../Leases';
class FleetCashflows extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader:false,
      skeletonLoaderOwner:false,
      skeletonLoaderLessor:false,
      skeletonLoaderAcType: false,
      skeletonLoaderTransType: false,
      pageLoader:false,
      cashflow:{ list:[], pagination:{} },
      filter: {},
      genericFilter:{},
      filterAccType:{},
      filterTransType:{},
      filterOwnerGraph:{},
      filterLessorGraph:{},
      open:false,
      mode:'list',
      open:false,
      cashflowAccTypeGraph:{},
      cashflowTransTypeGraph:{},
      cashflowOwnerGraphData:{},
      cashflowlessorGraphData:{},
      assetTypeOptions:[],
      assetModelOptions:[],
      assetsOptions:[],
      lesseeOptions:[],
      lessorOptions:[],
      ownerOptions:[],
      graphHeading:'',
      lesseeFilter:[],
      selectedTab: 0,
      cashflowRentalAccTypeGraph:{} 
     };
    this.getFleetCashflowApi = getFleetCashflowApi.bind(this);
    this.exportFleetCashflowsApi = exportFleetCashflowsApi.bind(this);
    this.getCashflowAccTypeGraphApi = getCashflowAccTypeGraphApi.bind(this);
    this.geCashflowTransTypeGraphApi = geCashflowTransTypeGraphApi.bind(this);
    this.getCashflowOwnerGraphApi = getCashflowOwnerGraphApi.bind(this);
    this.getLesseeFilterApi = getLesseeFilterApi.bind(this);
    this.getCashflowLessorGraphApi = getCashflowLessorGraphApi.bind(this);
    this.getAssetIdentifierApi = getAssetIdentifierApi.bind(this);
    this.getCashflowRenatlAccTypeGraphApi = getCashflowRenatlAccTypeGraphApi.bind(this);

  }
  componentDidMount(){
    const { genericFilter, filterAccType, filterTransType, filterOwnerGraph, filterLessorGraph } = this.state;
    this.getFleetCashflowApi(this.props, {per_page:50}, 'skeletonLoader');
    this.props.getPlatformConstantsLeaseType();
    this.props.getPlatformConstantsLeaseStatus();
    this.getCashflowAccTypeGraphApi(this.props, genericFilter, filterAccType, 'skeletonLoaderAcType');
    this.geCashflowTransTypeGraphApi(this.props, genericFilter, filterTransType, 'skeletonLoaderTransType');
    this.getCashflowOwnerGraphApi(this.props, {...genericFilter, page:1}, filterOwnerGraph, 'skeletonLoaderOwner');
    this.getCashflowLessorGraphApi(this.props, genericFilter, filterLessorGraph, 'skeletonLoaderLessor');
    this.getCashflowRenatlAccTypeGraphApi(this.props, genericFilter, filterAccType, 'skeletonLoaderAcType');

    globalFilterTypes.map((item) => 
      this.getAssetIdentifierApi(item.label, item.type)
    )
    trackActivity('Page Visited',{
      application_module: 'Lease Management',
      page_title: 'Fleet Cashflow List'
    })
    this.getLesseeFilterApi()
  }
  
  createSortHandler = (sortField) => {
    const { sort, sort_by, filter } = this.state;
    this.setState({
      sort: sortField,
      sort_by: (sortField === sort ? sort_by === 'asc' ? 'desc' : 'asc' : 'asc')
    });
    if (sortField === sort) {
      if (sort_by === 'asc') {
        this.getFleetCashflowApi(this.props, { ...filter, sort: sortField, sort_by: 'desc', per_page:50 }, 'pageLoader');
      } else {
        this.getFleetCashflowApi(this.props, { ...filter, sort: sortField, sort_by: 'asc', per_page:50 }, 'pageLoader');
      }
    } else {
      this.getFleetCashflowApi(this.props, { ...filter, sort: sortField, sort_by: 'asc', per_page:50 }, 'pageLoader');
    }
  }

  changeGenericFilter = (e, keyParam, value) => {
    let query = Object.assign({},this.state.genericFilter);
    if(value){
      query = {
        ...query,
        [keyParam]:value.id
      }
    }else{
      delete query[keyParam];
    }
    this.setState({genericFilter: query});
    this.applyGenericFilter(query);
  }

  applyGenericFilter = (genericFilter) => {
    const { filterAccType, filterTransType, filterOwnerGraph, filterLessorGraph } = this.state;
    this.getCashflowAccTypeGraphApi(this.props, genericFilter, filterAccType, 'skeletonLoaderAcType');
    this.geCashflowTransTypeGraphApi(this.props, genericFilter, filterTransType, 'skeletonLoaderTransType');
    this.getCashflowOwnerGraphApi(this.props, {...genericFilter, page:1}, filterOwnerGraph, 'skeletonLoaderOwner');
    this.getCashflowLessorGraphApi(this.props, genericFilter, filterLessorGraph, 'skeletonLoaderLessor');
  }

  moveFn = (move) => {
    const { cashflowOwnerGraphData, genericFilter, filterOwnerGraph } = this.state;
    let newPage = null;
    if(move === 'next'){
      newPage = parseInt(cashflowOwnerGraphData && cashflowOwnerGraphData.pagination.page)+1
    }else{
      newPage = parseInt(cashflowOwnerGraphData && cashflowOwnerGraphData.pagination.page)-1
    }
    this.getCashflowOwnerGraphApi(this.props, genericFilter, {page:newPage, ...filterOwnerGraph}, 'skeletonLoaderOwner');
  }
  onClickDataPointFn = (data, category, type) => {
    const { lessorOptions, ownerOptions, genericFilter, filterAccType, filterTransType, filterOwnerGraph, filterLessorGraph } = this.state;
    const monthInsight = monthsRevObj[category];
    if(type === 'basedOnReserveType'){
      this.setState({graphHeading:'Based on Reserve Rate Type'})
      const reserveType = cashflowFleetFilterOps.reserve_acc_type.options.find(item => item.label === data);
      if(reserveType){
        this.getFleetCashflowApi(this.props, {...genericFilter, ...filterAccType, reserve_acc_type:reserveType.id, monthInsight, per_page:50}, 'pageLoader', 'underlyingData');
      }
    }
    if(type === 'basedOnLessor'){
      this.setState({graphHeading:'Based on Lessor'})
      const lessor = lessorOptions.find(item => item.name === data);
      if(lessor){
        this.getFleetCashflowApi(this.props, {...genericFilter, ...filterLessorGraph, lessor_id:lessor.id, monthInsight, per_page:50}, 'pageLoader', 'underlyingData');
      }
    }
    if(type === 'basedOnOwner'){
      this.setState({graphHeading:'Based on Owner'})
      const owner = ownerOptions.find(item => item.name === data);
      if(owner){
        this.getFleetCashflowApi(this.props, {...genericFilter, ...filterOwnerGraph, owner_id:owner.id, monthInsight, per_page:50}, 'pageLoader', 'underlyingData');
      }
      
    }
    if(type === 'basedOnTransType'){
      this.setState({graphHeading:'Based on Transaction Type'})
      const transType = cashflowFleetFilterOps.transaction_type.options.find(item => item.label === data);
      if(transType){
        this.getFleetCashflowApi(this.props, {...genericFilter, ...filterTransType, transaction_type:transType.id, monthInsight, per_page:50}, 'pageLoader', 'underlyingData');
      }
      
    }
  }
  handleTabChange = (event, newValue) => {
    this.setState({ selectedTab:newValue });
  };
  render(){
    const { skeletonLoader, skeletonLoaderOwner, skeletonLoaderLessor, skeletonLoaderAcType, skeletonLoaderTransType, pageLoader, filter, filterAccType, filterTransType, filterOwnerGraph, filterLessorGraph,  cashflow, sort, sort_by, mode, open, cashflowAccTypeGraph, cashflowTransTypeGraph, cashflowOwnerGraphData, cashflowlessorGraphData, lessorOptions, lesseeOptions, ownerOptions, genericFilter, assetTypeOptions, assetModelOptions, assetsOptions, graphHeading, lesseeFilter, selectedTab, cashflowRentalAccTypeGraph } = this.state;
    const { pltConstantsLeaseType, lessee, pltConstantsLeaseStatus } = this.props;
    let filterOptions = cashflowFleetFilterOps;
    filterOptions = {
      ...filterOptions,
      lease_type: {
        ...filterOptions.lease_type,
        options: pltConstantsLeaseType
      },
      lessee_id: {
        ...filterOptions.lessee_id,
        options: lesseeOptions
      },
      lease_status: {
        ...filterOptions.lease_status,
        options: pltConstantsLeaseStatus
      },
      lessor_id: {
        ...filterOptions.lessor_id,
        options: lessorOptions
      },
      owner_id: {
        ...filterOptions.owner_id,
        options: ownerOptions
      },
      asset_type: {
        ...filterOptions.asset_type,
        options: assetTypeOptions
      },
      asset_model: {
        ...filterOptions.asset_model,
        options: assetModelOptions
      },
      serial_number: {
        ...filterOptions.serial_number,
        options: assetsOptions
      }
    }
    return(
      <section className="fleet-lease-management-sec">
        <DeploymentMessage/>
        { mode == 'list' ?
          <Fragment>
            { skeletonLoader ? (<STableLoader count={8} />):
              <Fragment>
                <Paper square style={{padding:'6px 10px'}}>
                  <Grid container spacing={1} alignItems='center'>
                    <Grid item md={9}>
                      <FilterComponent 
                        filter={filter}
                        filterMenu={filterOptions}
                        getResponseBack={(applyFilter) => {this.getFleetCashflowApi(this.props, {...applyFilter, page:1, per_page:cashflow.pagination.per_page}, 'pageLoader');}}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <ul className='list-inline' style={{float:'right', display:'flex'}}>
                        {cashflow.list.length > 0 && checkPermission('contracts','cashflow','EXP') &&
                          <li className="list-inline-item">
                            <ExportManu title="Export" files={[{title:'PDF', extension: 'pdf', key:''},{title:'Excel', extension: 'xls', key:''}, {title:'CSV', extension: 'csv', key:''}]} exportReportFn={(file) => this.exportFleetCashflowsApi(this.props, {...filter, download:file.extension})} />
                          </li>
                        }
                        { checkPermission('contracts','cashflow','ANA') ?
                          <li className='list-inline-item'>
                            <Switcher 
                              mode={mode}
                              onChangeMode={(mode) => this.setState({mode})}
                            />
                          </li>:null
                        }
                      </ul>
                    </Grid>
                  </Grid>
                </Paper>
                <TableListComp
                  heads={fleetCashflowHd}
                  sort={sort}
                  sort_by={sort_by}
                  data={cashflow.list.map((item,index) =>
                    <FleetCashflowsList
                      index={index}
                      item={item} />
                    )
                  }
                  createSortHandler={this.createSortHandler}
                  onChangePage={(event, newPage) => this.getFleetCashflowApi(this.props, {...filter, page:newPage+1, per_page:cashflow.pagination.per_page, sort: sort, sort_by: sort_by}, 'pageLoader')}
                  onChangeRowsPerPage={(event) => this.getFleetCashflowApi(this.props, {...filter, page:1, per_page: event.target.value, sort: sort, sort_by: sort_by}, 'pageLoader')}
                  pagination={cashflow.pagination}
                  noRecord={ cashflow.list.length ? null:
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                />
              </Fragment>
            }
          </Fragment> :
          <Fragment>
            <section className="fleet-lease-analytics-sec">
              <Paper className="generic-filter-block">
                <Grid container spacing={1}>
                  <Grid item xs={11} md={11}>
                    <FleetGenericFilter 
                      genericFilter={genericFilter}
                      lesseeOptions={lesseeOptions}
                      lessorOptions={lessorOptions}
                      assetTypeOptions={assetTypeOptions}
                      assetModelOptions={assetModelOptions}
                      assetsOptions={assetsOptions}
                      ownerOptions={ownerOptions}
                      changeGenericFilter={this.changeGenericFilter}
                      processingLoader={skeletonLoaderOwner || skeletonLoaderLessor || skeletonLoaderAcType || skeletonLoaderTransType}
                    />
                  </Grid>
                  <Grid item xs={1} md={1}>
                    <ul className='list-inline' style={{float:'right'}}>
                      <li className='list-inline-item'>
                        <Switcher 
                          mode={mode}
                          onChangeMode={(mode) => this.setState({mode})}
                        />
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <CashflowTransTypeGraph 
                    cashflowTransTypeGraph={cashflowTransTypeGraph}
                    skeletonLoader={skeletonLoaderTransType}
                    filter={filterTransType}
                    getResponseBack={(applyFilter)=>this.geCashflowTransTypeGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderTransType')}
                    callExternalFunction={this.onClickDataPointFn} 
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6} lg={6}>
                {jetStreamInstance?
                  <Paper>
                  <Tabs value={selectedTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary" variant="normal" >
                    <Tab label="Reserve" value={0} />
                    <Tab label="Rental" value={1} />
                  </Tabs>
                  </Paper>:null}
                  <CashflowAcTypeGraph 
                    selectedTab={selectedTab}
                    cashflowAccTypeGraph={cashflowAccTypeGraph} 
                    cashflowRentalAccTypeGraph={cashflowRentalAccTypeGraph}
                    filter={filterAccType}
                    skeletonLoader={skeletonLoaderAcType}
                    getResponseBack={(applyFilter)=>selectedTab===0?this.getCashflowAccTypeGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderAcType') : this.getCashflowRenatlAccTypeGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderAcType')}
                    callExternalFunction={this.onClickDataPointFn} 
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CashflowOwnerGraph 
                    cashflowOwnerGraphData={cashflowOwnerGraphData} 
                    filter={filterOwnerGraph}
                    skeletonLoader={skeletonLoaderOwner}
                    getResponseBack={(applyFilter)=>this.getCashflowOwnerGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderOwner')}
                    callExternalFunction={this.onClickDataPointFn} 
                    moveFn={(dir)=>this.moveFn(dir)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CashflowLessorGraph 
                    cashflowlessorGraphData={cashflowlessorGraphData} 
                    filter={filterLessorGraph}
                    skeletonLoader={skeletonLoaderLessor}
                    getResponseBack={(applyFilter)=>this.getCashflowLessorGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderLessor')}
                    callExternalFunction={this.onClickDataPointFn} 
                  />
                </Grid>
              </Grid>
            </section>
          </Fragment>
        }
        { open ? 
          <Dialog
            open={open}
            anchor="right"
            onClose={() => {this.setState({open:false});this.getFleetContractsApi(this.props, {per_page:50}, 'pageLoader');}}
            fullScreen
          >
            <DialogTitle id="form-dialog-title">
            {graphHeading ? graphHeading :'Underlying Data' }
              <ul className='list-inline' style={{float:'right'}}>
                <li className='list-inline-item'>
                  <ExportManu title="Export" files={[{title:'PDF', extension: 'pdf', key:''},{title:'Excel', extension: 'xls', key:''}, {title:'CSV', extension: 'csv', key:''}]} exportReportFn={(file) => this.exportFleetCashflowsApi(this.props, {...filter, download:file.extension})} />
                </li>
              </ul>
            </DialogTitle>
            <DialogContent dividers={true}>
                <TableListComp
                    heads={fleetCashflowHd}
                    data={cashflow.list.map((item,index) =>
                    <FleetCashflowsList
                        index={index}
                        item={item} />
                    )
                    }
                    pagination={cashflow.pagination}
                    onChangePage={(event, newPage) => this.getFleetCashflowApi(this.props, {...filter, page:newPage+1, per_page:cashflow.pagination.per_page, sort: sort, sort_by: sort_by}, 'pageLoader')}
                    onChangeRowsPerPage={(event) => this.getFleetCashflowApi(this.props, {...filter, page:1, per_page: event.target.value, sort: sort, sort_by: sort_by}, 'pageLoader')}
                    noRecord={ cashflow.list.length ? null:
                      <EmptyCollection
                          title="No records found"
                      />
                    }
                />
            </DialogContent>
            <DialogActions style={{paddingLeft:'24px',paddingRight:'24px'}}>
                <Button onClick={() => {this.setState({open:false});this.getFleetCashflowApi(this.props, {per_page:50}, 'pageLoader');}} color='primary' variant='outlined' size='small'>Close</Button>
            </DialogActions>
          </Dialog>:null    
        }
        { pageLoader ? <PageLoader/>:null }
      </section>
    )
  }
}

const mapStateToProps = state => ({
  pltConstantsLeaseType: state.shareReducer.pltConstantsLeaseType,
  pltConstantsLeaseStatus: state.shareReducer.pltConstantsLeaseStatus
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPlatformConstantsLeaseType: () => dispatch(getPlatformConstantsLeaseTypeAc({constant_types:['lease_type']})),
    getPlatformConstantsLeaseStatus: () => dispatch(getPlatformConstantsLeaseStatusAc({constant_types:['lease_status']}))
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(FleetCashflows,['contracts','cashflow','R'])))
