import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import moment from 'moment'
import NumericLabel from 'react-pretty-numbers';
import { displayDateFormat } from '../../../../constants';
import { showCurrencyFormat } from '../../../../utils';
import { getLocalStorageInfo } from '../../../../utils';
import config from '../../../../config';
import { jetStreamInstance } from '../../../../lease_management/Leases';
export default class DashboardCashFlowWrap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jspLessor: false
    }
  }
  componentDidMount() {
    let info = getLocalStorageInfo()
    if (info.defaultLessor.id == 442 || info.defaultLessor.id == 463) {
      this.setState({ jspLessor: true })
    }
  }
  render() {
    const { contrDbCashFlows, params , selectedTab} = this.props;

    return (
      <Col md="12" className="db-left-bar">
        <div className="db-cashflow">
          <div className="cashflow-top-bar">
           {selectedTab==0? <>
            <h5 className="para" style={{ position: "relative" }}>
              Cash Flow Summary (Reserve)
              <Link to={`${config.domain.subDomian}${params.type}/${params.aircraft_slug}/contract/${this.props.params.contract_slug}/cashflow`}>View All</Link>
            </h5>
            <Row>
              <Col md={jetStreamInstance?"3":"2"}>
                <p>MR Total Funds</p>
                {
                  contrDbCashFlows.mr ?
                    <NumericLabel params={showCurrencyFormat()}>{contrDbCashFlows.mr}</NumericLabel> : 'US$0.00'
                }
                <span className="as-of-date">As of {contrDbCashFlows.mr_detail_as_of_date ? moment(contrDbCashFlows.mr_detail_as_of_date).format(displayDateFormat) : 'date --'}</span>
              </Col>
              { this.state.jspLessor ?
              <Col md={jetStreamInstance?"3":"2"}>
                <p> ESP Total Funds </p>
                {
                  contrDbCashFlows.esp_summary ?
                    <NumericLabel params={showCurrencyFormat()}>{contrDbCashFlows.esp_summary.esp}</NumericLabel> : 'US$0.00'
                }
                 <span className="as-of-date">As of {contrDbCashFlows.esp_summary && contrDbCashFlows.esp_summary.esp_as_of_date? moment(contrDbCashFlows.esp_summary.esp_as_of_date).format(displayDateFormat) : 'date --'}</span>

              </Col>
              : null}
              {
                this.state.jspLessor ?
                  <Col md={jetStreamInstance?"3":"2"}>
                    <p> JSP Total Funds </p>
                    {
                      contrDbCashFlows.jsp_summary ?
                        <NumericLabel params={showCurrencyFormat()}>{contrDbCashFlows.jsp_summary.jsp}</NumericLabel> : 'US$0.00'
                    }
                    <span className="as-of-date">As of {contrDbCashFlows.jsp_summary && contrDbCashFlows.jsp_summary.jsp_as_of_date ? moment(contrDbCashFlows.jsp_summary.jsp_as_of_date).format(displayDateFormat) : 'date --'}</span>
                  </Col>
                  : null
                }
                { this.state.jspLessor ?
              <Col md={jetStreamInstance?"3":"2"}>
                <p> LSP Total Funds </p>
                {
                  contrDbCashFlows.lsp_summary ?
                  <NumericLabel params={showCurrencyFormat()}>{contrDbCashFlows.lsp_summary.lsp}</NumericLabel> : 'US$0.00'
                }
                <span className="as-of-date">As of {contrDbCashFlows.jsp_summary && contrDbCashFlows.lsp_summary.lsp_as_of_date ? moment(contrDbCashFlows.lsp_summary.lsp_as_of_date).format(displayDateFormat) : 'date --'}</span>
              </Col>
              :null}
              {!jetStreamInstance ?
              <Col md="2">
                <p>Rental Total Funds</p>
                {contrDbCashFlows.rental_new ?
                  contrDbCashFlows.rental_new.length ?
                    contrDbCashFlows.rental_new.map((rental, index) =>
                      <div>
                        <NumericLabel params={showCurrencyFormat(rental.currency)}>{rental.total_amount}</NumericLabel>
                      </div>
                    ) : 'US$0.00' : 'US $0.00'
                }
                <span className="as-of-date">As of {contrDbCashFlows.rental_detail_as_of_date ? moment(contrDbCashFlows.rental_detail_as_of_date).format(displayDateFormat) : 'date --'}</span>
              </Col>:null}
            </Row>
            </>:
            <>
            <h5 className="para" style={{ position: "relative" }}>
              Cash Flow Summary (Rental)
              <Link to={`${config.domain.subDomian}${params.type}/${params.aircraft_slug}/contract/${this.props.params.contract_slug}/cashflow`}>View All</Link>
            </h5>
            <Row>
              <Col md="3">
                <p>Rental Total Funds</p>
                {contrDbCashFlows.rental_new ?
                  contrDbCashFlows.rental_new.length ?
                    contrDbCashFlows.rental_new.map((rental, index) =>
                      <div>
                        <NumericLabel params={showCurrencyFormat(rental.currency)}>{rental.total_amount}</NumericLabel>
                      </div>
                    ) : 'US$0.00' : 'US $0.00'
                }
                <span className="as-of-date">As of {contrDbCashFlows.rental_detail_as_of_date ? moment(contrDbCashFlows.rental_detail_as_of_date).format(displayDateFormat) : 'date --'}</span>
              </Col>
              { this.state.jspLessor ?
              <Col md={jetStreamInstance?"3":"2"}>
                <p> ESP Total Funds </p>
                {
                  contrDbCashFlows.rental_esp_summary ?
                    <NumericLabel params={showCurrencyFormat()}>{contrDbCashFlows.rental_esp_summary.esp}</NumericLabel> : 'US$0.00'
                }
                 <span className="as-of-date">As of {contrDbCashFlows.rental_esp_summary && contrDbCashFlows.rental_esp_summary.esp_as_of_date? moment(contrDbCashFlows.rental_esp_summary.esp_as_of_date).format(displayDateFormat) : 'date --'}</span>

              </Col>
              : null}
              {
                this.state.jspLessor ?
                  <Col md={jetStreamInstance?"3":"2"}>
                    <p> JSP Total Funds </p>
                    {
                      contrDbCashFlows.rental_jsp_summary ?
                        <NumericLabel params={showCurrencyFormat()}>{contrDbCashFlows.rental_jsp_summary.jsp}</NumericLabel> : 'US$0.00'
                    }
                    <span className="as-of-date">As of {contrDbCashFlows.rental_jsp_summary && contrDbCashFlows.rental_jsp_summary.jsp_as_of_date ? moment(contrDbCashFlows.rental_jsp_summary.jsp_as_of_date).format(displayDateFormat) : 'date --'}</span>
                  </Col>
                  : null
                }
                { this.state.jspLessor ?
              <Col md={jetStreamInstance?"3":"2"}>
                <p> LSP Total Funds </p>
                {
                  contrDbCashFlows.rental_lsp_summary ?
                  <NumericLabel params={showCurrencyFormat()}>{contrDbCashFlows.rental_lsp_summary.lsp}</NumericLabel> : 'US$0.00'
                }
                <span className="as-of-date">As of {contrDbCashFlows.rental_lsp_summary && contrDbCashFlows.rental_lsp_summary.lsp_as_of_date ? moment(contrDbCashFlows.rental_lsp_summary.lsp_as_of_date).format(displayDateFormat) : 'date --'}</span>
              </Col>
              :null}
              </Row>
              </>}
          </div>
          {this.props.children}
        </div>
      </Col>
    )
  }
}
