import React, {Fragment} from 'react';
import moment from 'moment';
import { Link, withRouter } from 'react-router';
import { browserHistory } from 'react-router';
import { TableRow, TableCell } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../utils_v2';
import {assetsType} from '../../../constants';
const FleetCashflows = ({item, getResponseBack}) => {
  function invLink(cashDetail,id){
    if(cashDetail.split(/[\[\]]+/).length > 1){
      let str = cashDetail.split(/[\[\]]+/);
      return (
        <span>
          {str[0]}
          <Link to={`/${assetsType[item.asset.asset_type]}/${item.asset.slug}/contract/${item.lease_slug}/invoice/view/${item.invoice_id}`}>{str[1]}</Link>
          {str[2]}
        </span>
      )
    }else{
      return cashDetail;
    }
  }
  const redirectToCashflow = ()=>{
    if(item.asset.linked_aircraft_slug){
      window.open(`/aircraft/${item.asset.linked_aircraft_slug}/contract/${item.lease_slug}/cashflow`,'_blank')
    }else{
      window.open(`/${assetsType[item.asset.asset_type]}/${item.asset.slug}/contract/${item.lease_slug}/cashflow`,'_blank')
    }
  }
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell className="asset-info" style={{cursor:'pointer'}} onClick = {()=>redirectToCashflow()}>
          <div className="flex-centered asset-wrapper" style={{flexWrap:"nowrap"}}>
            <div className="assetIcon">
                <img style={{ height: '25px', objectFit: 'contain' }} src={"images/" + (item.asset.asset_type == 1 ? "icon_Aircraft.png" : (item.asset.asset_type == 2) ? "icon_engine.png" : item.asset.asset_type == 3 ? "icon_apu.svg" : item.asset.asset_type == 4 ? 'icon_lg.svg' : item.asset.asset_type == 5 ? 'icon_propeller.svg' : 'icon_engine.png')} alt="img" width="32" />
            </div>
            <div className="asset">
              {
                (item.asset.asset_type === 1 || item.asset.asset_type === 2  || item.asset.asset_type === 3 || item.asset.asset_type === 5) ?
                <p>
                    {item.asset.asset_type === 1 ? item.asset.name ? item.asset.name : item.asset.name : ''}{item.asset.asset_type === 1 ? item.asset.model && ' / ' + item.asset.model: ''}{item.asset.asset_type === 2 ? item.asset.name : ''}{item.asset.asset_type === 3 ? item.asset.name : ''}{item.asset.asset_type === 5 ? item.asset?.model ?item.asset.model: null : null}
                </p> : null
              }
                <h6>{item.asset.unique_name}</h6>
            </div>
          </div>
      </TableCell>
      <TableCell style={{cursor:'pointer'}} onClick = {()=>redirectToCashflow()}>{item.lessor_name && item.lessor_name.name ? item.lessor_name.name:'--'}</TableCell>
      <TableCell style={{cursor:'pointer'}} onClick = {()=>redirectToCashflow()}>{item.owner && item.owner.name ? item.owner.name:'--'}</TableCell>
      <TableCell style={{cursor:'pointer'}} onClick = {()=>redirectToCashflow()}>{item.lessee && item.lessee.logo ? <img style={{width:"60px"}} src={item.lessee && item.lessee.logo} alt=""/> : <span>{item.lessee && item.lessee.name ? item.lessee.name :'--'}</span>}</TableCell>
      <TableCell style={{textAlign:" -webkit-center", cursor:'pointer'}} onClick = {()=>redirectToCashflow()}>{item.lease_start_date ? item.lease_start_date : '--'} <br/><span>to</span><br/> {item.lease_end_date ? item.lease_end_date : '--'}</TableCell>
      <TableCell style={{cursor:'pointer'}} onClick = {()=>redirectToCashflow()}>{item.reserve_acc_type && item.reserve_acc_type.label ?  <>{item.reserve_acc_type.label} <span style={{ fontWeight: 'bold', fontFamily:'initial' }}>(Reserve)</span></> : item.rental_acc_type && item.rental_acc_type.label?<>{item.rental_acc_type.label} <span style={{ fontWeight: 'bold', fontFamily:'initial' }}>(Rental)</span></>:'--' }</TableCell>
      <TableCell style={{cursor:'pointer'}} onClick = {()=>redirectToCashflow()}>{item.currency ? item.currency : '--'}</TableCell>
      <TableCell style={{cursor:'pointer'}} onClick = {()=>redirectToCashflow()}>
        { item.credit_or_debit ?
          <NumericLabel params={getCurrencyFormat({currency:item.currency, shortFormat:false})}>{item.amount}</NumericLabel>
          :'--'
        }
      </TableCell>
      <TableCell style={{cursor:'pointer'}} onClick = {()=>redirectToCashflow()}>
        { !item.credit_or_debit ?
          <Fragment><NumericLabel params={getCurrencyFormat({currency:item.currency, shortFormat:false})}>{item.amount}</NumericLabel></Fragment>
          :'--'
        }
      </TableCell>
      <TableCell style={{cursor:'pointer'}} onClick = {()=>redirectToCashflow()}>
        {item.balance ? <NumericLabel params={getCurrencyFormat({currency:item.currency, shortFormat:false})}>{item.balance}</NumericLabel> : '--'}
      </TableCell>
      <TableCell style={{width:'200px'}}>{item.transaction_details ? invLink(item.transaction_details, item.invoice_id):'--'}</TableCell>
    </TableRow>
  )
}
export default FleetCashflows;
