import { Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import { InfoIcon } from '../../../../shared';
import { jetStreamInstance } from '../../../../lease_management/Leases';

export default class LeaseRentalHeader extends Component {
 constructor(props) {
  super(props);
 }
 render() {
  return (
   <thead>
    <tr>
     <th> Type</th>
     <th> Account Type</th>
     <th> Currency</th>
     <th> Interval</th>
     <th> Amount</th>
     <th> Escalation Percentage</th>
     <th>Start Date</th>
     <th>End Date 
        {
            this.props?.rentalInfo?.contract_rental_details?.lease_duration_type?.value == 3?
            <Tooltip title='Tentative end date based on Average utilisation' arrow placement='top'>
            <span>
            <InfoIcon/>
            </span>
        </Tooltip>
        : null}
     </th>
     <th></th>

    </tr>
   </thead>
  )
 }
}